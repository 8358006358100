<template>
  <el-date-picker
    style='width: 18rem;'
    v-model='dateRange'
    type='daterange'
    range-separator='~'
    start-placeholder='Start date'
    end-placeholder='End date'
    size='small'
    @change='filterClosePriceByDateRange'>
  </el-date-picker>
</template>

<script>
import { mapActions } from 'vuex'
import dayjs                    from 'dayjs'
export default {
  name: 'ClosePriceFilters',
  data () {
    return {
      dateRange: [dayjs().subtract(2, 'day'), dayjs()],
    }
  },
  computed: {
    startDate () {
      return this.dateRange ? dayjs(this.dateRange[0]).format('YYYY-MM-DD') : null
    },
    endDate () {
      return this.dateRange ? dayjs(this.dateRange[1]).format('YYYY-MM-DD') : null
    },
  },
  methods: {
    ...mapActions('listings', [
      'getListingCloseData',
      'updateFilterDateRange',
    ]),
    filterClosePriceByDateRange () {
      this.updateFilterDateRange({startDate: this.startDate, endDate: this.endDate})
      this.refreshListingCloseData()
    },
    refreshListingCloseData () {
      this.getListingCloseData({ start_date: this.startDate, end_date: this.endDate })
    },
  },
  mounted () {
    this.filterClosePriceByDateRange()
  }
}
</script>
